import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Spin, Flex, Input, Button } from 'antd';
import { sendVerificationCode } from 'repository/auth/AuthRepository';

export const UserVerificationStep = ({ userEmail, finishForm }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [codeInput, setCodeInput] = useState('');
  const [loading, setLoading] = useState(true);

  const createRandomCodeAndSendEmail = useCallback(async () => {
    setLoading(true);

    const code = Math.floor(100000 + Math.random() * 900000);
    setVerificationCode(code.toString());

    try {
      sendVerificationCode(userEmail, code, 'vic');
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: <div>Hi ha hagut un error en l'enviament del mail amb el codi.</div>,
        okText: 'Ok',
        centered: true,
      });
    }

    setLoading(false);
  }, [userEmail]);

  const handleVerify = () => {
    setLoading(true);

    if (verificationCode === codeInput) {
      finishForm();
    } else {
      Modal.error({
        title: 'Error',
        content: <div>El codi no és correcte.</div>,
        okText: 'Ok',
        centered: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    createRandomCodeAndSendEmail();
  }, [createRandomCodeAndSendEmail]);

  const formStyle = {
    width: '100%',
    maxWidth: '500px',
  };

  return (
    <Spin
      tip="Loading..."
      spinning={loading}
    >
      <Flex
        vertical
        gap="large"
        style={formStyle}
      >
        <div>
          Hem enviat un correu electrònic a <span style={{ fontWeight: 'bold' }}>{userEmail}</span> amb un codi de
          verificació. Si us plau, introduïu-lo a continuació:
        </div>
        <Flex
          gap="small"
          align="center"
        >
          <Input
            value={codeInput}
            onChange={(e) => setCodeInput(e.target.value)}
            placeholder="Codi de verificació"
          />
          <Button
            onClick={async () => {
              await createRandomCodeAndSendEmail();
              Modal.info({
                title: 'Codi reenviat',
                content: (
                  <div>
                    S'ha tornat a enviar un codi a l'adreça <span style={{ fontWeight: 'bold' }}>{userEmail}</span>.
                  </div>
                ),
                okText: 'Acceptar',
                centered: true,
              });
            }}
          >
            Reenviar codi
          </Button>
        </Flex>
        <Flex
          gap="small"
          vertical
          align="center"
        >
          <Button
            type="primary"
            onClick={handleVerify}
          >
            Continuar
          </Button>
        </Flex>
      </Flex>
    </Spin>
  );
};
