import React, { useState } from 'react';
import { Form, Card, Radio, Space, Tooltip, Button, Select, Input, Flex, Modal, Spin } from 'antd';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { AiFillInfoCircle, AiOutlineDelete, AiTwotoneFileText } from 'react-icons/ai';
import { postWhitelistEntries, postFormDataWhitelistEntries } from 'repository/accessForm/AccessFormRepository';
import { relationshipOptions } from './constants';
import colors from 'components/Buttons/colors';

const { Option } = Select;

const uploadContentText = {
  1: 'Cliqueu per afegir el permís de circulació del vehicle',
  2: "Cliqueu per afegir l'assegurança (documentació o rebut) del vehicle",
  3: 'Cliqueu per afegir el contracte o document acreditatiu del vincle entre el sol·licitant i el vehicle',
};

export const LicensePlatesStep = ({
  formFields,
  onFormFieldsChange,
  nextStep,
  prevStep,
  singlePage,
  username,
  history,
  authorizedZones,
}) => {
  const [state, setState] = useState(formFields);

  const [enableFormError, setEnableFormError] = useState('');
  const [selectedZone, setSelectedZone] = useState(undefined);
  const [selectZoneError, setSelectZoneError] = useState('');
  const [licensePlateRequiredField, setLicensePlateRequiredFieldError] = useState('');
  const [agreementError, setAgreementError] = useState('');
  const [modalLoading, setModalLoading] = useState(false);

  const onFinish = async () => {
    const hasErrors = validateFields(state.plateFields);
    if (hasErrors) {
      return;
    }

    if (singlePage) {
      if (state.enableFormValue === false) {
        history.push('/license-plates');
        return;
      }

      await createLicensePlates();
    } else {
      let currentState = state;
      if (state.enableFormValue === false) {
        currentState = {
          enableFormValue: false,
          plateFields: [],
          agreement: undefined,
        };
      }
      onFormFieldsChange(currentState);
      showWarning();
    }
  };

  const getRelationship = (key) => {
    return relationshipOptions[key - 1];
  };

  function mapLicensePlateFields(licensePlatesFormFields) {
    return licensePlatesFormFields.plateFields.map((field) => {
      return {
        plate: field.plate,
        relationship: getRelationship(field.relationship),
        attachedFileName: field.attachedFile?.name,
      };
    });
  }

  async function createLicensePlates() {
    setModalLoading(true);
    const licensePlates = {
      username: username,
      plateFields: mapLicensePlateFields(state),
    };

    const form = {
      DNI: username,
      licensePlates: mapLicensePlateFields(state),
    };

    const licensePlateFiles = state.plateFields.map((field) => field.attachedFile);
    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss').toString();

    try {
      await postWhitelistEntries(licensePlates, timestamp, selectedZone, 'vic');
      const zone = authorizedZones.filter((zone) => zone.id === selectedZone);
      await postFormDataWhitelistEntries(form, licensePlateFiles, timestamp, zone[0].name);
      setModalLoading(false);
      Modal.info({
        title: 'Petició enviada',
        content: <div>S'ha enviat la petició de matrícula/s permanent/s correctament.</div>,
        okText: 'Acceptar',
        onOk: () => history.push('/license-plates'),
        centered: true,
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: <div>Hi ha hagut un error en l'enviament de la sol·licitud.</div>,
        okText: 'Tornar al menu principal',
        onOk: () => history.push('/license-plates'),
        centered: true,
      });
    }
  }

  const showWarning = () => {
    Modal.confirm({
      title: 'Alerta',
      width: '500px',
      content: (
        <div>
          Abans de continuar repasseu bé les dades introduïdes perquè no podreu tornar enrere per a modificar-les.
        </div>
      ),
      okText: 'Continuar',
      cancelText: 'Tornar al formulari',
      onOk: () => {
        nextStep();
      },
      centered: true,
    });
  };

  function validateFields() {
    let hasErrors = false;

    if (singlePage && selectedZone === undefined) {
      setSelectZoneError('Si us plau, indiqueu la zona');
      hasErrors = true;
    } else {
      setSelectZoneError('');
    }

    if (state.enableFormValue === undefined) {
      setEnableFormError('Si us plau, indiqueu si voleu afegir mes matrícules');
      hasErrors = true;
    } else {
      setEnableFormError('');
    }

    if (state.enableFormValue === false) {
      return false;
    }

    if (state.plateFields.length === 0) {
      hasErrors = true;
      setLicensePlateRequiredFieldError(`Si us plau, afegiu com a mínim 1 matrícula`);
    } else {
      setLicensePlateRequiredFieldError(``);
    }

    state.plateFields.forEach((field, index) => {
      if (field.relationship === 1) {
        if (state.agreement === false && !field.attachedFile) {
          setLicensePlateRequiredFieldError(`Si us plau, afegiu el fitxer de la Matrícula ${index + 1}`);
          hasErrors = true;
        }
      } else if (field.relationship === 2 || field.relationship === 3) {
        if (!field.attachedFile) {
          setLicensePlateRequiredFieldError(`Si us plau, afegiu el fitxer de la Matrícula ${index + 1}`);
          hasErrors = true;
        }
      }

      if (!field.relationship) {
        setLicensePlateRequiredFieldError(
          `Si us plau, seleccioneu la relació que té amb el vehicle de la Matrícula ${index + 1}`
        );
        hasErrors = true;
      }

      if (!field.plate) {
        setLicensePlateRequiredFieldError(`Si us plau, introduïu la Matrícula ${index + 1}`);
        hasErrors = true;
      }
    });

    if (option1IsSelected() && state.agreement === undefined) {
      setAgreementError(`Si us plau, indiqueu si accepteu el consentiment de dades`);
      hasErrors = true;
    } else {
      setAgreementError(``);
    }

    return hasErrors;
  }

  const renderSelectOptions = () => {
    return relationshipOptions.map((option, index) => (
      <Option
        key={index}
        value={index + 1}
      >
        {`${index + 1}. ${option}`}
      </Option>
    ));
  };

  const renderAuthorizedZonesOptions = () => {
    return authorizedZones.map((zone) => (
      <Option
        key={zone.id}
        value={zone.id}
      >
        {zone.name}
      </Option>
    ));
  };

  const renderFile = (fileName, fieldId) => {
    return (
      fileName && (
        <Flex
          gap="small"
          align="center"
          justify="space-between"
          style={{
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid lightgray',
            // background: "white",
          }}
        >
          <Flex
            align="center"
            gap="small"
          >
            <AiTwotoneFileText style={{ fontSize: '20px' }} />
            {fileName}
          </Flex>
          <AiOutlineDelete
            style={{ fontSize: '17px', cursor: 'pointer' }}
            onClick={() => {
              updateField(fieldId, 'attachedFile');
              document.getElementById(`files${fieldId}`).value = '';
            }}
          />
        </Flex>
      )
    );
  };

  const createUploadElement = (relationship, fieldId) => {
    let file = undefined;
    state.plateFields.forEach((field) => {
      if (field.id === fieldId) {
        file = field.attachedFile;
      }
    });

    if (relationship !== undefined) {
      return (
        <div>
          <label
            htmlFor={`files${fieldId}`}
            style={{
              width: '100%',
              background: 'white',
              borderRadius: '10px',
              border: '2px solid rgb(0,0,0,0.2)',
              padding: '10px',
              borderStyle: 'dashed',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.border = '2px solid rgb(52, 116, 235,0.5)';
              e.currentTarget.style.borderStyle = 'dashed';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.border = '2px solid rgb(0,0,0,0.2)';
              e.currentTarget.style.borderStyle = 'dashed';
            }}
          >
            <Flex
              vertical
              justify="center"
              align="center"
              gap="small"
              style={{ textAlign: 'center' }}
            >
              <UploadOutlined />
              <div>{uploadContentText[relationship]}</div>
            </Flex>
          </label>
          {renderFile(file?.name, fieldId)}
          <input
            id={`files${fieldId}`}
            style={{ display: 'none' }}
            type="file"
            onChange={(event) => {
              const newFile = event.target.files[0];
              const updatedFields = state.plateFields.map((f) => {
                if (f.id === fieldId) {
                  return { ...f, attachedFile: newFile };
                }
                return f;
              });
              setState((prev) => {
                return { ...prev, plateFields: updatedFields };
              });
            }}
          />
        </div>
      );
    }
    return null;
  };

  const addField = () => {
    const newField = {
      id: uuid(),
      plate: undefined,
      relationship: undefined,
      attachedFile: undefined,
    };

    setState((prev) => {
      return { ...prev, plateFields: [...state.plateFields, newField] };
    });
    setLicensePlateRequiredFieldError('');
  };

  const removeField = (id) => {
    const filteredFields = state.plateFields.filter((field) => field.id !== id);

    setState((prev) => {
      return { ...prev, plateFields: filteredFields };
    });
  };

  function updateField(id, name) {
    const updatedFields = state.plateFields.map((f) => {
      if (f.id === id) {
        return { ...f, [name]: undefined };
      }
      return f;
    });

    setState((prev) => {
      return { ...prev, plateFields: updatedFields };
    });
  }

  function option1IsSelected() {
    const filteredFields = state.plateFields.filter((field) => field.relationship === 1);
    return filteredFields.length !== 0;
  }

  function renderAgreementField() {
    if (option1IsSelected()) {
      return (
        <Flex
          vertical
          gap="small"
        >
          <Flex
            align="center"
            gap="3px"
            style={{ fontWeight: 'bold' }}
          >
            <span style={{ color: 'red' }}>* </span>Accepteu el{' '}
            <span
              style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();

                Modal.info({
                  title: 'Consentiment de dades',
                  width: '1000px',
                  content: (
                    <div>
                      <div>
                        De conformitat amb el Reglament (UE) 2016/679, de 27 d'abril de 2016, relatiu a la protecció de
                        les persones físiques pel que fa al tractament de dades personals i a la lliure circulació
                        d'aquestes dades, així com amb la Llei Orgànica 3/2018, de 5 de desembre de Protecció de Dades i
                        Garantia de Drets Digitals, L'AJUNTAMENT DE VIC, responsable del tractament, facilita la següent{' '}
                        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                          informació bàsica sobre Protecció de Dades
                        </span>
                        :
                      </div>
                      <ul>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>
                            Finalitat del tractament de les seves dades personals:
                          </span>{' '}
                          Gestionar la seva alta per a la concessió de les autorització d'accés a les illes de vianants
                          així com obtenir
                        </li>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>Legitimació:</span> El tractament de les dades queda
                          legitimat en virtut de una missió realitzada en l'interès públic (art. 6.1 e) del RGPD).
                        </li>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>Destinataris:</span> Les seves dades no seran comunicades
                          a tercers.
                        </li>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>Drets:</span> Podreu accedir, rectificar, exercir el dret
                          d'oposició i suprimir les dades, així com limitar el tractament o exercir el dret a la
                          portabilitat de les dades al domicili del responsable, carrer de la Ciutat, núm. 1, 08500 de
                          Vic (Barcelona) o posant-se en contacte amb el nostre Delegat de Protecció de dades a través
                          del correu electrònic <a href="mailto:dpdajuntament@vic.cat">dpdajuntament@vic.cat</a>.
                        </li>
                        <li>
                          <span style={{ fontWeight: 'bold' }}>Conservació:</span> Les seves dades seran conservades
                          durant el temps necessari per a les finalitats del tractament per a les quals han estat
                          recollides i en aplicació de la normativa d'arxius aplicable al responsable.
                        </li>
                      </ul>
                      <div>
                        L'AJUNTAMENT DE VIC l'informa que compleix amb tots els requisits establerts per la normativa de
                        protecció de dades i amb totes les mesures tècniques i organitzatives necessàries per garantir
                        la seguretat de les dades de caràcter personal.
                      </div>
                      <div>
                        Així mateix, en cas d'incompliment per part del responsable en el tractament de les seves dades
                        personals, vostè té dret a interposar una reclamació davant l'Autoritat Catalana de Protecció de
                        Dades <a href="https://www.apdcat.cat">www.apdcat.cat</a>.
                      </div>
                    </div>
                  ),
                  okText: 'Tanca',
                  centered: true,
                });
              }}
            >
              consentiment de dades
            </span>
            ?
            <Tooltip
              placement="topLeft"
              title={
                "No acceptar el consentiment de dades implica la necessitat d’afegir el document 'Permís de circulació' en els casos en els quals la relació que es té amb el vehicle sigui 'Soc titular del vehicle'"
              }
              color={colors.blue.main}
            >
              <AiFillInfoCircle style={{ fontSize: '17px', marginLeft: '7px' }} />
            </Tooltip>
          </Flex>
          <Radio.Group
            onChange={(e) => {
              setState((prev) => {
                return { ...prev, agreement: e.target.value };
              });
            }}
            value={state.agreement}
          >
            <Radio
              style={{ fontWeight: '400' }}
              value={true}
            >
              Sí
            </Radio>
            <Radio
              style={{ fontWeight: '400' }}
              value={false}
            >
              No
            </Radio>
          </Radio.Group>
          {agreementError && <div style={{ color: 'red' }}>{agreementError}</div>}
        </Flex>
      );
    }
  }

  const formStyle = {
    width: '100%',
    maxWidth: '500px',
  };

  return (
    <Spin spinning={modalLoading}>
      <Flex
        vertical
        gap="large"
        style={formStyle}
      >
        <Flex
          vertical
          gap="small"
        >
          <div style={{ fontWeight: 'bold' }}>
            <span style={{ color: 'red' }}>* </span>Voleu afegir alguna matrícula?
          </div>

          <Radio.Group
            onChange={(e) => {
              setState((prev) => {
                return { ...prev, enableFormValue: e.target.value };
              });
            }}
            value={state.enableFormValue}
          >
            <Radio
              style={{ fontWeight: '400' }}
              value={true}
            >
              Si
            </Radio>
            <Radio
              style={{ fontWeight: '400' }}
              value={false}
            >
              No
            </Radio>
          </Radio.Group>
          {enableFormError && <div style={{ color: 'red' }}>{enableFormError}</div>}
        </Flex>

        {state.enableFormValue && (
          <Flex vertical>
            {singlePage && (
              <Flex
                vertical
                style={{ marginBottom: '20px' }}
                gap="small"
              >
                <div style={{ fontWeight: 'bold' }}>
                  <span style={{ color: 'red' }}>* </span>Indiqueu la zona en la qual voleu donar d'alta les matrícules:
                </div>
                <Select
                  value={selectedZone}
                  placeholder="Escull una zona"
                  onChange={(value) => {
                    setSelectedZone(value);
                  }}
                >
                  {renderAuthorizedZonesOptions()}
                </Select>
                {selectZoneError && <div style={{ color: 'red' }}>{selectZoneError}</div>}
              </Flex>
            )}

            <Space
              direction="vertical"
              style={{ marginBottom: '15px' }}
            >
              <div style={{ fontWeight: 'bold' }}>
                <span style={{ color: 'red' }}>* </span>Indiqueu les matrícules que voleu donar d'alta
              </div>
              <div style={{ fontWeight: '400' }}>Indiqueu també la relació que teniu amb el vehicle:</div>
              <ul style={{ listStyle: 'none', fontWeight: '400' }}>
                <li>1. Soc titular del vehicle</li>
                <li>2. No soc titular del vehicle però en soc conductor habitual</li>
                <li>
                  3. El vehicle està en règim de <i>renting</i>, <i>lising</i> o lloguer amb un contracte superior a 3
                  mesos
                </li>
              </ul>
              <div style={{ fontWeight: '400' }}>
                Per cada alta s’ha d’indicar la matrícula i la relació que teniu amb el vehicle (1,2 o 3)
              </div>
            </Space>

            <Flex
              vertical
              gap="large"
            >
              {state.plateFields.map((field, index) => {
                const relationship = field.relationship;
                let uploadElement = createUploadElement(relationship, field.id);

                if (relationship === 1) {
                  uploadElement = state.agreement ? null : uploadElement;
                } else {
                  uploadElement = createUploadElement(relationship, field.id);
                }

                return (
                  <Card
                    key={field.id}
                    style={{ background: '#f0f0f0', maxWidth: '100%' }}
                    size="small"
                    title={<div style={{ fontWeight: 'bold' }}>Matrícula {index + 1}</div>}
                    extra={<CloseOutlined onClick={() => removeField(field.id)} />}
                  >
                    <Flex
                      vertical
                      gap="middle"
                    >
                      <Flex gap="small">
                        <Input
                          key={`plate ${field.id}`}
                          value={field.plate}
                          placeholder="Matrícula"
                          style={{ width: '100px' }}
                          onChange={(e) => {
                            const updatedFields = state.plateFields.map((f) => {
                              if (f.id === field.id) {
                                return { ...f, plate: e.target.value };
                              }
                              return f;
                            });
                            setState((prev) => {
                              return { ...prev, plateFields: updatedFields };
                            });
                          }}
                        />

                        <Select
                          key={`relationship ${field.id}`}
                          value={field.relationship}
                          placeholder="Relació"
                          style={{ width: '150px' }}
                          dropdownStyle={{ width: '50%' }}
                          onChange={(value) => {
                            const updatedFields = state.plateFields.map((f) => {
                              if (f.id === field.id) {
                                return { ...f, relationship: value };
                              }
                              return f;
                            });
                            setState((prev) => {
                              return { ...prev, plateFields: updatedFields };
                            });
                          }}
                        >
                          {renderSelectOptions()}
                        </Select>
                      </Flex>

                      {uploadElement}
                    </Flex>
                  </Card>
                );
              })}

              <Button
                type="dashed"
                onClick={() => {
                  addField();
                }}
                block
              >
                + Afegir matrícula
              </Button>
            </Flex>
            {licensePlateRequiredField && <div style={{ color: 'red' }}>{licensePlateRequiredField}</div>}
          </Flex>
        )}

        {renderAgreementField()}

        <Form.Item>
          {!singlePage && (
            <Button
              style={{ margin: '5px' }}
              onClick={() => {
                onFormFieldsChange(state);
                prevStep();
              }}
            >
              Tornar al pas anterior
            </Button>
          )}
          <Button
            type="primary"
            style={{ float: 'right', margin: '5px' }}
            onClick={onFinish}
          >
            {singlePage ? 'Finalitzar' : 'Finalitzar el formulari'}
          </Button>
        </Form.Item>
      </Flex>
    </Spin>
  );
};
