import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import HeaderLinks from 'components/HeaderLinks.jsx';
import image from 'assets/img/orgtconnect-background.jpg';
import defaultAvatar from 'assets/img/default-avatar.png';
import zinkinDataLogo from 'assets/img/ZinkinData_100_25.png';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import dashboardRoutes from 'routes/dashboard.jsx';
import { userRoles } from 'util/Constants';
import { withTranslation } from 'react-i18next';
import { Space, Tooltip, Typography } from 'antd';
import { MdKeyboardArrowRight } from 'react-icons/md';
import colors from './Buttons/colors';
import styles from './sidebar.module.css';
import CustomAvatar from './CustomAvatar';

const { Text } = Typography;
var perfectScroll;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openUsuarios: this.activeRoute('/gestio-usuaris') !== '' ? true : false,
      openConfig: this.activeRoute('/configuracio') !== '' ? true : false,
      openInformes: this.activeRoute('/informes') !== '' ? true : false,
      openSat: this.activeRoute('/assistencia-tecnica') !== '' ? true : false,
      isWindows: navigator.platform.indexOf('Win') > -1 ? true : false,
      width: window.innerWidth,
      pending: 0,
      currentCenter: this.props.currentCenter.name,
      currentUser: this.props.currentUser,

      openAvatar: false,
      avatar: defaultAvatar,
      center: 'nexotech',
    };
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();

    // add event listener for windows resize
    window.addEventListener('resize', this.updateDimensions.bind(this));
    if (navigator.platform.indexOf('Win') > -1) {
      perfectScroll = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    if (this.props.currentCenter.logo) {
      this.setState({ avatar: this.props.currentCenter.logo });
    }
  }
  componentDidUpdate() {
    if (navigator.platform.indexOf('Win') > -1) {
      setTimeout(() => {
        perfectScroll.update();
      }, 350);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      perfectScroll.destroy();
    }
  }

  renderSections() {
    const sections = dashboardRoutes.filter(
      (section) => section.roles && section.roles.includes(this.state.currentUser.role)
    );

    return sections.map((section, key) => {
      return (
        <li
          className={this.activeRoute(section.path)}
          key={key}
        >
          <NavLink
            to={section.path}
            className="nav-link"
            activeClassName="active"
          >
            <i>
              <section.icon />
            </i>
            <p>{this.props.t(section.name)}</p>
          </NavLink>
        </li>
      );
    });
  }

  renderProfile() {
    const { currentUser } = this.props;

    return (
      <>
        <Space
          align="center"
          direction="vertical"
          style={{ width: '100%', padding: '15px', marginBottom: '-10%' }}
          onClick={() => {
            this.props.history.push({
              pathname: '/profile',
            });
          }}
        >
          <CustomAvatar src={this.state.avatar} />
          <Tooltip
            title={this.props.t('sidebar.see-profile')}
            placement="right"
            color={colors.blue.main}
          >
            <Space
              align="center"
              className={styles.editProfile}
              style={{ color: 'white', fontSize: '20px' }}
            >
              <span>{currentUser.name}</span>
              {currentUser.surnames != null && (
                <span style={{ fontWeight: 'bold' }}>{currentUser.surnames.split(' ')[0]}</span>
              )}
              <MdKeyboardArrowRight style={{ fontSize: '20px', verticalAlign: 'middle' }} />
            </Space>
          </Tooltip>
          <Text style={{ color: '#AAA', fontSize: '15px' }}>{userRoles[currentUser.role]}</Text>
        </Space>
      </>
    );
  }

  render() {
    return (
      <div
        className="sidebar"
        data-color="black"
        data-image={image}
      >
        <div className="sidebar-background" />
        <div
          className="logo"
          style={{ backgroundColor: '#222' }}
        >
          <div className="logo-big">
            <img
              src={zinkinDataLogo}
              style={{
                width: '60%',
                margin: '17px 0px',
                filter: 'drop-shadow(5px 5px 3px #151515)',
              }}
              alt="lprconnect-logo"
            />
          </div>
        </div>
        <div
          className="sidebar-wrapper"
          style={{ backgroundColor: '#222' }}
          ref="sidebarWrapper"
        >
          {this.renderProfile()}
          <ul className="nav">
            {this.state.width <= 992 ? <HeaderLinks /> : null}
            {this.renderSections()}
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(Sidebar);
