export const reasons = [
  { key: 0, label: "Soc propietari d'un habitatge de dins de l'illa de vianants" },
  { key: 1, label: "Soc propietari d'un local de dins de l'illa de vianants" },
  { key: 2, label: "Soc propietari d'un garatge de dins de l'illa de vianants" },
  {
    key: 3,
    label: "Soc resident o llogater d'un habitatge on hi estic empadronat",
  },
  {
    key: 4,
    label: "Soc llogater d'un local, d'un garatge o d'un habitatge on no hi estic empadronat",
  },
  // { key: 5, label: 'Soc major de 75 anys empadronat a Vic' },
  {
    key: 6,
    label: 'Tinc un establiment amb llicència municipal d’activitats a Vic',
  },
  { key: 7, label: 'Necessito un accés causal (accés màxim de 3 mesos)' },
];

export const zonesOptions = [
  { key: 23, label: 'Centre històric' },
  { key: 24, label: 'Eixample Morató' },
  { key: 25, label: 'Carrer de Gurb' },
  { key: 7, label: 'El Portalet' },
];

export const relationshipOptions = [
  'Soc titular del vehicle',
  'No soc titular del vehicle però en soc conductor habitual',
  'El vehicle està en règim de renting, lising o lloguer amb contracte superior a 3 mesos',
];
