import React, { useState } from 'react';
import moment from 'moment';
import { Spin, Form, Space, Button, Radio, Typography, Checkbox, Flex, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AiOutlineDelete, AiTwotoneFileText } from 'react-icons/ai';
import { postZoneAuthorizations, postFormDataZones } from 'repository/accessForm/AccessFormRepository';
import { reasons, zonesOptions } from './constants';

export const ZonesStep = ({ formFields, onFormFieldsChange, nextStep, prevStep, singlePage, username, history }) => {
  const [state, setState] = useState(formFields);
  const [accessReasonError, setAccessReasonError] = useState('');
  const [selectedZonesError, setSelectedZonesError] = useState('');
  const [uploadError, setUploadError] = useState('');
  const [modalLoading, setModalLoading] = useState(false);

  const [zonesDisabled, setZonesDisabled] = useState(
    () => formFields.accessReason === '5' || formFields.accessReason === '6'
  );
  const [showUpload, setShowUpload] = useState(
    () => formFields.accessReason === '4' || formFields.accessReason === '7'
  );

  function validateFields() {
    let hasError = false;

    if (!state.accessReason) {
      setAccessReasonError("Si us plau, indiqueu una rao d'accés");
      hasError = true;
    } else {
      setAccessReasonError('');
    }

    if (state.selectedZones.length === 0) {
      setSelectedZonesError('Si us plau, indiqueu una zona');
      hasError = true;
    } else {
      if (state.accessReason !== '5' && state.accessReason !== '6') {
        if (state.selectedZones.length > 1) {
          setSelectedZonesError('Només es pot seleccionar una única zona');
          hasError = true;
        } else {
          setSelectedZonesError('');
        }
      }
    }

    if (showUpload) {
      if (!state.attachedFile) {
        setUploadError('Es obligatori pujar el fitxer');
        hasError = true;
      } else {
        setUploadError('');
      }
    }

    return hasError;
  }

  const getLabel = (accessReasonKey) => {
    const foundReason = reasons.find((reason) => {
      const num = parseInt(accessReasonKey, 10);
      return reason.key === num;
    });
    return foundReason.label;
  };

  const getZoneName = (zoneId) => {
    const zone = zonesOptions.find((zone) => zone.key === zoneId);
    return zone.label;
  };

  const onFinish = async () => {
    const hasError = validateFields();
    if (hasError) {
      return;
    }

    if (singlePage) {
      await createZoneAuthorizations();
    } else {
      onFormFieldsChange(state);
      nextStep();
    }
  };

  async function createZoneAuthorizations() {
    setModalLoading(true);

    const zones = {
      username: username,
      accessReason: getLabel(state.accessReason),
      selectedZones: state.selectedZones,
      attachedFile: state.attachedFile,
    };

    const form = {
      DNI: username,
      accessReason: zones.accessReason,
      zones: zones.selectedZones.map((zone) => {
        return { id: zone, name: getZoneName(zone) };
      }),
      zoneAttachedFileName: state.attachedFile?.name,
    };

    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss').toString();

    try {
      await postFormDataZones(form, state.attachedFile, timestamp);
      await postZoneAuthorizations(zones, timestamp, 'vic');
      setModalLoading(false);
      Modal.info({
        title: 'Petició enviada',
        content: <div>S'ha enviat la petició d'accés a la zona correctament.</div>,
        okText: 'Acceptar',
        onOk: () => history.push('/citizen-authoritzations'),
        centered: true,
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: <div>Hi ha hagut un error en l'enviament de la sol·licitud.</div>,
        okText: 'Tornar al menu principal',
        onOk: () => history.push('/license-plates'),
        centered: true,
      });
    }
  }

  const renderText = () => {
    if (state.accessReason === '4') {
      return 'Cliqueu per afegir el contracte de lloguer';
    } else if (state.accessReason === '7') return 'Cliqueu per afegir l’acreditació de la necessitat';
  };

  const resetFile = () => {
    setState((prev) => {
      return { ...prev, attachedFile: undefined };
    });
    const element = document.getElementById(`attachedFile`);
    if (element) {
      element.value = '';
    }
  };

  const renderFile = (fileName) => {
    return (
      fileName && (
        <Flex
          gap="small"
          align="center"
          justify="space-between"
          style={{
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid lightgray',
            // background: "white",
          }}
        >
          <Flex
            align="center"
            gap="small"
          >
            <AiTwotoneFileText style={{ fontSize: '20px' }} />
            {fileName}
          </Flex>
          <AiOutlineDelete
            style={{ fontSize: '17px', cursor: 'pointer' }}
            onClick={() => {
              resetFile();
            }}
          />
        </Flex>
      )
    );
  };

  const fontWeightNormal = {
    fontWeight: '400',
  };
  const formStyle = {
    width: '100%',
    maxWidth: '500px',
  };

  return (
    <Spin spinning={modalLoading}>
      <Flex
        vertical
        gap="large"
        style={formStyle}
      >
        <Flex
          vertical
          gap="small"
        >
          <div style={{ fontWeight: 'bold' }}>
            <span style={{ color: 'red' }}>* </span>Indiqueu una de les opcions segons escaigui (si compliu dues o més
            condicions, trieu-ne una):
          </div>

          <Radio.Group
            onChange={(e) => {
              const value = e.target.value;
              let newState = { ...state };

              newState.accessReason = value;

              if (state.accessReason === '5' || state.accessReason === '6') {
                newState.selectedZones = [];
              }

              if (value === '5' || value === '6') {
                newState.selectedZones = [23, 24, 25, 7];
                setZonesDisabled(true);
              } else {
                setZonesDisabled(false);
              }

              setState(newState);

              if (value === '4' || value === '7') {
                setShowUpload(true);
              } else {
                setShowUpload(false);
                resetFile();
              }
            }}
            value={state.accessReason}
          >
            <Space direction="vertical">
              <Typography level={5}>Soc propietari:</Typography>
              {reasons.slice(0, 3).map((reason) => (
                <Radio
                  key={reason.key}
                  value={String(reason.key)}
                  style={fontWeightNormal}
                >
                  {reason.label}
                </Radio>
              ))}

              <Typography
                style={{ marginTop: '10px' }}
                level={5}
              >
                No soc propietari:
              </Typography>
              {reasons.slice(3, 5).map((reason) => (
                <Radio
                  key={reason.key}
                  value={String(reason.key)}
                  style={fontWeightNormal}
                >
                  {reason.label}
                </Radio>
              ))}

              <Typography
                style={{ marginTop: '10px' }}
                level={5}
              >
                Altres:
              </Typography>
              {reasons.slice(5).map((reason) => (
                <Radio
                  key={reason.key}
                  value={String(reason.key)}
                  style={fontWeightNormal}
                >
                  {reason.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          {accessReasonError && <div style={{ color: 'red' }}>{accessReasonError}</div>}
        </Flex>

        <Flex
          vertical
          gap="small"
        >
          <div style={{ fontWeight: 'bold' }}>
            <span style={{ color: 'red' }}>* </span>Indiqueu una única zona:
          </div>

          <Checkbox.Group
            onChange={(checkedValues) => {
              setState((prev) => {
                return { ...prev, selectedZones: checkedValues };
              });
            }}
            value={state.selectedZones}
          >
            <Flex vertical>
              {zonesOptions.map((option) => {
                return (
                  <Checkbox
                    key={option.key}
                    value={option.key}
                    disabled={zonesDisabled}
                    checked={zonesDisabled}
                    style={fontWeightNormal}
                  >
                    {option.label}
                  </Checkbox>
                );
              })}
            </Flex>
          </Checkbox.Group>
          {selectedZonesError && <div style={{ color: 'red' }}>{selectedZonesError}</div>}
        </Flex>

        {showUpload && (
          <Flex
            vertical
            gap="small"
          >
            <div style={{ fontWeight: 'bold' }}>
              <span style={{ color: 'red' }}>* </span>Afegiu la documentació demanada:
            </div>
            <div>
              <label
                htmlFor={`attachedFile`}
                style={{
                  width: '100%',
                  background: 'white',
                  borderRadius: '10px',
                  border: '2px solid rgb(0,0,0,0.2)',
                  padding: '10px',
                  borderStyle: 'dashed',
                  cursor: 'pointer',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.border = '2px solid rgb(52, 116, 235,0.5)';
                  e.currentTarget.style.borderStyle = 'dashed';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.border = '2px solid rgb(0,0,0,0.2)';
                  e.currentTarget.style.borderStyle = 'dashed';
                }}
              >
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  gap="small"
                  style={{ textAlign: 'center' }}
                >
                  <UploadOutlined />
                  <div>{renderText()}</div>
                </Flex>
              </label>
              {renderFile(state.attachedFile?.name)}
              <input
                id={`attachedFile`}
                style={{ display: 'none' }}
                type="file"
                onChange={(event) => {
                  const newFile = event.target.files[0];
                  setState((prev) => {
                    return { ...prev, attachedFile: newFile };
                  });
                }}
              />
            </div>

            {uploadError && <div style={{ color: 'red' }}>{uploadError}</div>}
          </Flex>
        )}

        <Form.Item>
          {!singlePage && (
            <Button
              style={{ margin: '5px' }}
              onClick={() => {
                onFormFieldsChange(state);
                prevStep();
              }}
            >
              Tornar al pas anterior
            </Button>
          )}
          <Button
            type="primary"
            onClick={onFinish}
            style={{ float: 'right', margin: '5px' }}
          >
            {singlePage ? 'Finalitzar' : 'Següent'}
          </Button>
        </Form.Item>
      </Flex>
    </Spin>
  );
};
